<template>
  <div class="card-project card-project--mobile">
    <div class="card-project__header ">
      <div class="card-project__header-info card-project__header-info--settings">
        <div class="card-project__avatar">
          <font-awesome-icon
            :icon="dataIconUser" />
        </div>
        <div class="card-project__title">
          {{ dataUserObj.name }}
        </div>
      </div>
      <div>
        <call-button
          type="danger"
          :icon="dataIconLock"
          plain
          full
          @click="$root.$emit('logout')">
          Logout
        </call-button>
      </div>
    </div>
  </div>
</template>

<script>
import CallButton from '../../components/common/CallButton';
import { USER_INFO } from '../../store/types/actions.types';
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import storage from '../../service/storage';

export default {
  name: 'PageSettingsMobile',
  components: { CallButton },
  data () {
    return {
      dataIconLock: faSignOutAlt,
      dataIconUser: faUser,
      dataUserObj: {}
    };
  },
  computed: {
    computedToken () {
      const { token } = storage.get('fcm_token_obj-v1') ?? {};
      return token;
    }
  },
  async created () {
    try {
      const { user = {} } = await this.$store.dispatch(`user/${USER_INFO}`);
      this.dataUserObj = user;
    } catch (e) {
      throw Error(e);
    }
  }
};
</script>
